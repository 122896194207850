import ListIcon from "assets/icons/ListIcon"
import MapIcon from "assets/icons/MapIcon"
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks"
import Link from "next/link"
import { useRouter } from "next/router"
import React from "react"
import { favouritesReducerActions } from "store/reducers/favouritesReducer"
import { rootPaths } from "utils/constants/rootPaths"
import { layoutLang } from "utils/language/layout"

const NavigateButton = () => {
  const { query, pathname, locale } = useRouter()
  const { location, ...getQuery } = query
  const { setChangeFilterType } = favouritesReducerActions
  const dispatch = useAppDispatch()
  const { location: currentLocation, filterType } = useAppSelector(
    (state) => state.favouritesReducer
  )

  // location
  const newLocation =
    currentLocation?.regionValue && currentLocation?.regionValue?.city
      ? currentLocation?.regionValue?.city?.url
      : currentLocation?.regionValue?.district?.city?.url || "gorod-tashkent"

  const isMap = pathname?.includes("karta")

  const path_name = isMap
    ? `/${newLocation}${filterType === "apartment"
      ? rootPaths.APARTMENTS
      : rootPaths.RESIDENTIAL_COMPLEXES
    }`
    : rootPaths.MAP

  const handleNavigate = () => {
    pathname.includes("kvartiri")
      ? dispatch(setChangeFilterType("apartment"))
      : dispatch(setChangeFilterType("complex"))
  }

  console.log(location)

  return (
    <Link
      href={{ pathname: path_name, query: getQuery }}
      className="fixed bottom-[110px] left-4 z-[99] bg-[#00A389] h-11 px-4 rounded-xl flex items-center justify-center cursor-pointer text-base font-semibold"
      onClick={handleNavigate}
    >
      {isMap ? (
        <div className="flex items-center gap-2.5 !text-white">
          <ListIcon />
          <span>{layoutLang[String(locale)]["royxat"]}</span>
        </div>
      ) : (
        <div className="flex items-center gap-2.5 !text-white">
          <MapIcon stroke="#fff" />
          <span>{layoutLang[String(locale)]["Xarita"]}</span>
        </div>
      )}
    </Link>
  )
}

export default NavigateButton
