import React from "react"

const WalkIcon = () => {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.33573 1.63349C8.18573 2.38016 7.44823 2.85849 6.57948 2.73016C6.38634 2.69343 6.20296 2.62124 6.03997 2.51779C5.87699 2.41434 5.73766 2.28169 5.63008 2.12755C5.52249 1.97341 5.44881 1.80085 5.4133 1.6199C5.37779 1.43895 5.38116 1.25321 5.42323 1.07349C5.50593 0.715178 5.7371 0.40193 6.0663 0.202077C6.3955 0.00222379 6.79602 -0.0680165 7.1805 0.006678C7.56497 0.0813725 7.9022 0.29494 8.11861 0.600795C8.33503 0.906649 8.41308 1.27997 8.33573 1.63932V1.63349ZM3.74198 9.01266L0.404476 12.1918C0.312654 12.2778 0.24099 12.3806 0.194039 12.4938C0.147089 12.6069 0.125879 12.728 0.131757 12.8492C0.137635 12.9705 0.170472 13.0893 0.228182 13.198C0.285893 13.3068 0.367213 13.4031 0.466976 13.481C0.891976 13.8252 1.55448 13.796 1.94823 13.4227L5.12948 10.401L4.06698 9.55516C3.88573 9.40932 3.77323 9.21682 3.74198 9.01266ZM2.03573 7.37932C2.47948 7.68849 3.11698 7.60099 3.44823 7.19849L4.91698 5.41349L4.99198 5.39016L4.37323 8.42349C4.31073 8.72682 4.43573 9.02432 4.66698 9.22849C4.71073 9.27516 4.75448 9.31599 4.81073 9.35682L7.02948 11.0135L8.19823 13.446C8.42323 13.9127 9.03573 14.1285 9.57323 13.936C9.69658 13.895 9.8096 13.8307 9.90526 13.7472C10.0009 13.6638 10.0772 13.5628 10.1293 13.4507C10.1815 13.3386 10.2084 13.2177 10.2084 13.0955C10.2085 12.9732 10.1816 12.8523 10.1295 12.7402L8.86698 10.1035C8.80448 9.97516 8.70448 9.85849 8.57948 9.76516L6.53573 8.24266L6.95448 6.17766L7.42948 6.69099C7.57323 6.84266 7.76073 6.95349 7.97948 7.00599L10.0607 7.49016C10.6232 7.61849 11.1982 7.32682 11.342 6.84266C11.492 6.35266 11.1607 5.85099 10.6045 5.72266L8.85448 5.31432L7.25448 3.57599C7.17661 3.49158 7.08354 3.42051 6.97948 3.36599C6.80776 3.2386 6.59839 3.16328 6.37948 3.15016C6.25448 3.14432 6.12948 3.15016 6.00448 3.19099L4.01073 3.73932C3.80448 3.79766 3.62948 3.91432 3.49823 4.06599L1.82948 6.09016C1.49823 6.49849 1.58573 7.07016 2.03573 7.37349V7.37932Z"
        fill="#98A2B3" />
    </svg>

  )
}

export default WalkIcon
