import React from "react"
import { useRouter } from "next/router"
import { layoutLang } from "utils/language/layout"
import { Collapse } from "antd"
import {
  useGetMainRegions,
  useGetMetros,
} from "pages/main-layout/services/queries"
import CustomLink from "common/custom-link"
import regions from "./regions.json"

import styles from "./footerFilter.module.scss"
import NonSSRWrapper from "common/non-ssr/NonSSRWrapper"
import { cx } from "utils/cx"
import ArrowIcon from "assets/icons/ArrowIcon"
import { useAppDispatch } from "hooks/reduxHooks"
import { favouritesReducerActions } from "store/reducers/favouritesReducer"

const { Panel } = Collapse

const FooterFilter = () => {
  const { query, locale, pathname } = useRouter()
  const { data } = useGetMainRegions()
  const { data: metros } = useGetMetros()
  const { setCurrentRegion } = favouritesReducerActions
  const dispatch = useAppDispatch()

  const Cities = () => (
    <div className={styles.container_elements}>
      {regions?.map((item) => {
        return (
          <CustomLink
            href={
              pathname?.includes("kvartiri")
                ? `/gorod-tashkent/kvartiri?city_id=12&district_id=${item?.id}`
                : `/gorod-tashkent/novostroyki?city_id=12&district_id=${item?.id}`
            }
            key={item?.id}
            // prefetch={false}
            onClick={() => {
              dispatch(
                setCurrentRegion({
                  type: "location",
                  value: {
                    city: null,
                    district: {
                      id: item?.id,
                      city: {
                        id: 12,
                        name: { ru: "город Ташкент", uz: "Toshkent shahri" },
                        url: "gorod-tashkent",
                      },
                      name: {
                        uz: item?.name,
                        ru: item?.name_ru,
                      },
                    },
                  },
                })
              )
            }}
          >
            <p
              className={`${
                item?.id === Number(query?.district_id)
                  ? "text-[#086ADA]"
                  : "text-[#3A4252]"
              } text-base font-normal cursor-pointer`}
            >
              {locale === "uz"
                ? item?.name?.replace("tumani", "")
                : item?.name_ru?.replace("район", "")}
            </p>
          </CustomLink>
        )
      })}
    </div>
  )

  const Regions = () => (
    <div className={styles.container_elements}>
      {data?.map((item) => {
        return (
          <CustomLink
            href={
              pathname?.includes("kvartiri")
                ? `/${item?.url}/kvartiri?city_id=${item?.id}`
                : `/${item?.url}/novostroyki?city_id=${item?.id}`
            }
            key={item?.id}
            // prefetch={false}
            onClick={() => {
              dispatch(
                setCurrentRegion({
                  type: "location",
                  value: { city: item, district: null },
                })
              )
            }}
          >
            <p
              className={`${
                item?.id === Number(query?.city_id)
                  ? "text-[#086ADA]"
                  : "text-[#3A4252]"
              } text-base font-normal cursor-pointer`}
            >
              {locale === "uz"
                ? item?.name?.uz?.replace("viloyati", "")
                : item?.name?.ru?.replace("область", "")}
            </p>
          </CustomLink>
        )
      })}
    </div>
  )

  const Metros = () => (
    <div className={styles.container_elements}>
      {metros?.map((item) => {
        return (
          <div className="flex flex-col gap-3" key={item?.id}>
            <span className="text-[#101828] text-base font-semibold">
              {locale === "uz" ? item?.name?.uz : item?.name?.ru}
            </span>
            {item?.metros?.map((el) => (
              <CustomLink
                href={
                  pathname?.includes("kvartiri")
                    ? `/gorod-tashkent/kvartiri?nearest_place_ids%5B%5D=${el?.id}`
                    : `/gorod-tashkent/novostroyki?nearest_place_ids%5B%5D=${el?.id}`
                }
                key={el?.id}
                // prefetch={false}
              >
                <p
                  className={`${
                    el?.id === Number(query?.["nearest_place_ids%5B%5D"])
                      ? "text-[#086ADA]"
                      : "text-[#3A4252]"
                  } text-base font-normal cursor-pointer`}
                >
                  {locale === "uz" ? el?.name?.uz : el?.name?.ru}
                </p>
              </CustomLink>
            ))}
          </div>
        )
      })}
    </div>
  )

  return (
    <div className={styles.container}>
      <div className={styles.container_body}>
        <div>
          <h3 className="text-[#101828] text-xl font-semibold mb-6">
            {pathname?.includes("kvartiri")
              ? layoutLang[locale as keyof typeof layoutLang][
                  "Toshkentdagi_yangi_kvartiralar"
                ]
              : layoutLang[locale as keyof typeof layoutLang][
                  "Toshkentdagi_yangi_uylar"
                ]}
          </h3>
          <Cities />
        </div>
        <div>
          <h3 className="text-[#101828] text-xl font-semibold mb-6">
            {pathname?.includes("kvartiri")
              ? layoutLang[locale as keyof typeof layoutLang][
                  "Butun_Ozbekiston_boylab_kvartiralar"
                ]
              : layoutLang[locale as keyof typeof layoutLang][
                  "Butun_Ozbekiston_boylab_yangi_uylar"
                ]}
          </h3>
          <Regions />
        </div>
        <div>
          <h3 className="text-[#101828] text-xl font-semibold mb-6">
            {layoutLang[String(locale)]["Metro yaqinidagi uylar"]}
          </h3>
          <Metros />
        </div>
      </div>
      <NonSSRWrapper>
        <div className={styles.container_bodyCollapse}>
          <Collapse
            expandIconPosition="end"
            bordered={false}
            defaultActiveKey={["1", "2", "3"]}
            expandIcon={(props) => (
              <ArrowIcon
                stroke="#101828"
                width={20}
                height={20}
                isRotated={!props?.isActive}
              />
            )}
          >
            <Panel
              className="bg-white mb-8"
              header={
                <h3 className="text-[#101828] text-xl font-semibold w-[90%]">
                  {pathname?.includes("kvartiri")
                    ? layoutLang[locale as keyof typeof layoutLang][
                        "Toshkentdagi_yangi_kvartiralar"
                      ]
                    : layoutLang[locale as keyof typeof layoutLang][
                        "Toshkentdagi_yangi_uylar"
                      ]}
                </h3>
              }
              key={"1"}
            >
              <Cities />
            </Panel>
            <Panel
              className="bg-white"
              header={
                <h3 className="text-[#101828] text-xl font-semibold w-[90%]">
                  {pathname?.includes("kvartiri")
                    ? layoutLang[locale as keyof typeof layoutLang][
                        "Butun_Ozbekiston_boylab_kvartiralar"
                      ]
                    : layoutLang[locale as keyof typeof layoutLang][
                        "Butun_Ozbekiston_boylab_yangi_uylar"
                      ]}
                </h3>
              }
              key={"2"}
            >
              <Regions />
            </Panel>
            <Panel
              className="bg-white mb-8 mt-8"
              header={
                <h3 className="text-[#101828] text-xl font-semibold w-[93%]">
                  {layoutLang[String(locale)]["Metro yaqinidagi uylar"]}
                </h3>
              }
              key={"3"}
            >
              <Collapse
                bordered={false}
                defaultActiveKey={[String(metros?.[0]?.id)]}
                accordion={true}
                className={cx(
                  "flex flex-col items-center justify-center gap-3 w-full",
                  styles.metros_collapse
                )}
                expandIconPosition="right"
                expandIcon={(props) => (
                  <ArrowIcon
                    stroke="#101828"
                    width={20}
                    height={20}
                    isRotated={!props?.isActive}
                  />
                )}
              >
                {metros?.map((metro) => (
                  <Panel
                    className="bg-[#F2F4F7] rounded-lg py-2 px-3 w-full"
                    key={String(metro?.id)}
                    header={
                      <h3
                        className="text-[#1D2939] text-xl font-semibold w-[93%] truncate"
                        title={
                          locale === "uz" ? metro?.name?.uz : metro?.name?.ru
                        }
                      >
                        {locale === "uz" ? metro?.name?.uz : metro?.name?.ru}
                      </h3>
                    }
                  >
                    <div className="flex flex-col gap-[10px]">
                      {metro?.metros?.map((item) => (
                        <CustomLink
                          href={
                            pathname?.includes("kvartiri")
                              ? `/gorod-tashkent/kvartiri?nearest_place_ids%5B%5D=${item?.id}`
                              : `/gorod-tashkent/novostroyki?nearest_place_ids%5B%5D=${item?.id}`
                          }
                          key={item?.id}
                        >
                          <span
                            className={cx(
                              "text-base font-normal text-[#3A4252] truncate",
                              item?.id ===
                                Number(query?.["nearest_place_ids%5B%5D"])
                                ? "text-[#086ADA]"
                                : "text-[#3A4252]"
                            )}
                            title={
                              locale === "uz" ? item?.name?.uz : item?.name?.ru
                            }
                          >
                            {locale === "uz" ? item?.name?.uz : item?.name?.ru}
                          </span>
                        </CustomLink>
                      ))}
                    </div>
                  </Panel>
                ))}
              </Collapse>
            </Panel>
          </Collapse>
        </div>
      </NonSSRWrapper>
    </div>
  )
}

export default FooterFilter
