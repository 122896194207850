import { stringify, UrlObject } from "query-string";
import React from "react";

const CustomLink: React.FC<
  React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    children?: React.ReactNode;
    key?: React.Key;
    href: string | (UrlObject & string);
  }
> = props => {
  const { href, className, children, onClick, ...rest } = props;

  const onNavigate = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    // e.preventDefault();
    // e.stopPropagation();
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <a
      {...rest}
      href={typeof href === "string" ? href : `?${stringify((href as UrlObject).query!)}`}
      onClick={onNavigate}
      className={className ?? "decoration-none hover:pointer-events-auto"}
    >
      {children}
    </a>
  );
};

export default CustomLink;
