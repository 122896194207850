import React from 'react';

const ThirdSmileIcon: React.FC = () => {
 return (
   <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
     <path
       d="M19.0018 2.00016C28.1999 1.95993 36.0246 9.42504 35.9982 19.0527C35.9725 28.444 28.3824 36.0323 18.9418 35.9996C9.43074 35.9675 2.00606 28.2415 2.00378 19.0468C2.00129 9.59665 9.573 2.00016 19.0018 2.00016Z"
       fill="#FECA00" />
     <path
       d="M8.37593 20.0833C8.3745 19.6758 8.45347 19.272 8.60832 18.895C8.76317 18.5181 8.99086 18.1754 9.27834 17.8866C9.56582 17.5978 9.90745 17.3686 10.2836 17.2121C10.6598 17.0555 11.0632 16.9748 11.4706 16.9745C13.1922 16.9722 14.6108 18.3898 14.5924 20.094C14.5738 21.8105 13.1799 23.1953 11.4734 23.1926C9.76181 23.1899 8.37616 21.7991 8.37593 20.0833Z"
       fill="#171615" />
     <path
       d="M29.6254 20.0874C29.6253 20.9104 29.2986 21.6997 28.7171 22.2819C28.1357 22.8641 27.3469 23.1917 26.5241 23.1926C24.8185 23.1926 23.4219 21.8028 23.409 20.0902C23.396 18.3821 24.8123 16.9704 26.5348 16.9745C28.2491 16.9784 29.6261 18.3652 29.6254 20.0874Z"
       fill="#171615" />
     <path
       d="M19.0119 24.5864C20.433 24.5839 21.8519 24.6241 23.2637 24.8058C23.645 24.8549 23.8433 25.0537 23.8383 25.3817C23.8333 25.7038 23.6237 25.8959 23.241 25.9391C20.7114 26.2248 18.1769 26.2087 15.6425 26.0347C15.3309 26.0134 15.0209 25.9704 14.7114 25.9334C14.3708 25.8925 14.1696 25.6897 14.1634 25.3853C14.1568 25.0634 14.3528 24.854 14.7085 24.8088C16.136 24.6262 17.5728 24.5814 19.0119 24.5864Z"
       fill="#171615" />
   </svg>

 );
}

export default ThirdSmileIcon;