export const PAGE = "page"
export const SIZE = "size"
export const VIEW_TYPE = "view-type"
export const ROOMS_COUNT = "rooms_count"
export const APARTMENT_ID = "apartment_id"
export const LOCATION = "location"
export const COMPLEX_ID = "complex_id"
export const YEAR = "year"
export const DISTRICT_ID = "district_id"
export const DEADLINE = "deadline"
export const DEADLINE_YEARS = "deadline_years"
