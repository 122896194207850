import React from 'react';

const FirstSmileIcon: React.FC = () => {
 return (
   <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
     <path
       d="M19.0008 2.00016C28.2004 1.95993 36.0263 9.42511 35.9999 19.0529C35.9743 28.4442 28.3829 36.0326 18.9408 35.9999C9.42816 35.9678 2.00227 28.2417 2 19.047C1.9975 9.59672 9.57044 2.00016 19.0008 2.00016Z"
       fill="#FECA00" />
     <path
       d="M28.1126 17.5839C28.7059 18.0417 29.0854 18.5977 29.2457 19.2819C29.608 20.8274 28.4857 22.4226 26.8186 22.7225C25.0913 23.0333 23.4257 22.0018 23.1224 20.4347C23.0925 20.2802 23.1187 20.0654 23.0197 19.9874C22.9165 19.9062 22.6929 19.9608 22.5225 19.949C21.7081 19.8925 21.0697 19.433 20.9242 18.7954C20.8806 18.6043 20.8438 18.4055 21.0301 18.2543C21.2164 18.103 21.426 18.1484 21.6151 18.2406C22.2677 18.5587 22.9216 18.4158 23.5701 18.2489C24.6797 17.9633 25.6518 17.401 26.6509 16.8945C27.6202 16.4031 28.5341 15.8234 29.5191 15.3564C29.5903 15.3197 29.6638 15.287 29.7393 15.2585C29.9362 15.1914 30.1204 15.21 30.2492 15.3771C30.3754 15.541 30.3549 15.7118 30.2197 15.8656C29.8162 16.3253 29.3337 16.7071 28.8321 17.0691C28.5981 17.239 28.362 17.4065 28.1126 17.5839Z"
       fill="#171615" />
     <path
       d="M8.69801 19.8594C8.7017 19.0041 9.05103 18.2886 9.73078 17.7165C9.8766 17.5938 9.85791 17.548 9.7107 17.4474C9.04573 16.9929 8.38998 16.5268 7.84498 15.9431C7.67909 15.7654 7.59002 15.583 7.75269 15.3773C7.91767 15.1675 8.13686 15.2002 8.36137 15.3019C9.34891 15.7496 10.2534 16.3296 11.2149 16.8203C12.1653 17.3063 13.0956 17.8383 14.1327 18.1595C14.7165 18.3402 15.312 18.4984 15.9451 18.3903C16.1369 18.3575 16.3007 18.2676 16.4791 18.2083C16.8875 18.0728 17.1445 18.2468 17.1076 18.6504C17.0573 19.2028 16.7015 19.5543 16.1881 19.7808C15.8739 19.9171 15.5273 19.9761 15.1814 19.9521C15.0155 19.9423 14.9461 19.9814 14.9258 20.1482C14.7453 21.6407 13.5035 22.7223 11.9232 22.7758C10.3796 22.8281 9.05173 21.8314 8.73908 20.3884C8.70124 20.2133 8.70424 20.0371 8.69801 19.8594Z"
       fill="#171615" />
     <path
       d="M14.2778 26.9402C14.2833 26.7748 14.3549 26.6081 14.4293 26.4443C15.0087 25.1674 16.0024 24.3978 17.2573 24.0154C19.2423 23.4105 21.0803 23.7171 22.6805 25.1646C23.1541 25.5931 23.4911 26.1347 23.6898 26.7616C23.7805 27.0475 23.6748 27.3177 23.4475 27.4392C23.2157 27.5632 22.9428 27.4964 22.7846 27.2498C22.0826 26.156 21.0585 25.6188 19.8628 25.4133C18.6455 25.2047 17.4807 25.3989 16.4044 26.0587C15.9268 26.3516 15.5268 26.7391 15.2261 27.2315C15.077 27.4758 14.8567 27.5365 14.6148 27.4603C14.3965 27.3919 14.2809 27.21 14.2778 26.9402Z"
       fill="#171615" />
   </svg>

 );
}

export default FirstSmileIcon;