import JKArrowLeftIcon from "assets/icons/JKArrowLeftIcon"
import QuizIcon from "assets/icons/QuizIcon"
import Link from "next/link"
import React, { useEffect, useState } from "react"

import districts from "../../pages/main-layout/components/footer-filter/districts.json"
import { useRouter } from "next/router"
import UysotIcon from "assets/icons/UysotIcon"
import { NewResidentalComplexModel } from "utils/models/residentialComplexModel"
import ComplexCard from "common/common-card/complex/ComplexCard"
import { viewTypes } from "utils/constants/viewTypes"
import NonSSRWrapper from "common/non-ssr/NonSSRWrapper"
import { layoutLang } from "utils/language/layout"
import { isBrowser } from "utils/helpers/isBrowser"
import { maxPrices, prePayment } from "./utils/constants/quizConstants"
import { QuizStateModel } from "./utils/models/quizStateModel"
import QuizComponent1 from "./components/QuizComponent1"
import QuizComponent2 from "./components/QuizComponent2"
import QuizComponent3 from "./components/QuizComponent3"
import QuizComponent4 from "./components/QuizComponent4"
import { PaymentModel } from "./utils/models/paymentModel"
import QuizComponent5 from "./components/QuizComponent5"
import QuizComponent6 from "./components/QuizComponent6"

export const itemStyle =
  "border border-solid border-[#EAECF0] rounded-[10px] px-3.5 py-2.5 text-[#101828] text-base font-medium cursor-pointer hover:!border-[#0DC683] hover:bg-[#EEFBF6] transition-all duration-300 flex items-center justify-between"

const Quiz = () => {
  const { locale } = useRouter()
  const [page, setPage] = useState<number>(1)
  const [quizData, setQuizData] = useState<NewResidentalComplexModel[]>([])
  const [quizState, setQuizState] = useState<QuizStateModel>({
    name: "",
    phone: "",
  })

  const [roomsCount, setRoomsCount] = useState<number[]>([])
  const [total_amount, setTotalAmount] = useState<string>("")

  const [status, setStatus] = useState(false)

  const getPhone = isBrowser() && localStorage.getItem("phone")

  useEffect(() => {
    setQuizState((state) => ({
      ...state,
      phone: String(getPhone)?.replace(/\D/g, ""),
    }))
  }, [getPhone])

  const finalPayment: PaymentModel[] =
    quizState?.payment_method_id === 2 ? prePayment : maxPrices

  const leftSide = [
    {
      id: 1,
      component: (
        <QuizComponent1
          quizState={quizState}
          setQuizState={setQuizState}
          page={page}
          setPage={setPage}
        />
      ),
    },
    {
      id: 2,
      component: (
        <QuizComponent2
          page={page}
          quizState={quizState}
          setPage={setPage}
          roomsCount={roomsCount}
          setRoomsCount={setRoomsCount}
        />
      ),
    },
    {
      id: 3,
      component: (
        <QuizComponent3
          page={page}
          quizState={quizState}
          setPage={setPage}
          setQuizState={setQuizState}
        />
      ),
    },
    {
      id: 4,
      component: (
        <QuizComponent4
          page={page}
          quizState={quizState}
          setPage={setPage}
          setQuizState={setQuizState}
          finalPayment={finalPayment}
          setTotalAmount={setTotalAmount}
          total_amount={total_amount}
        />
      ),
    },
    {
      id: 5,
      component: (
        <QuizComponent5
          page={page}
          quizState={quizState}
          setPage={setPage}
          setQuizState={setQuizState}
        />
      ),
    },

    {
      id: 6,
      component: (
        <QuizComponent6
          page={page}
          quizState={quizState}
          setPage={setPage}
          setQuizState={setQuizState}
          finalPayment={finalPayment}
          total_amount={total_amount}
          setStatus={setStatus}
          roomsCount={roomsCount}
          setQuizData={setQuizData}
        />
      ),
    },
  ]

  const filterLeftSide =
    quizState?.payment_method_id === 2
      ? leftSide
      : leftSide?.filter((item) => item?.id !== 5)

  const currentComponent = filterLeftSide?.find(
    (item) => item?.id === page
  )?.component

  const currentDistrict = districts?.find(
    (item) => item?.id === quizState?.city_id
  )?.url_name

  const moreLink = `/${currentDistrict}/kvartiri?city_id=${quizState?.city_id}`

  return (
    <div className="bg-white flex items-center">
      {!status ? (
        <div className="max-w-[1230px] mx-auto flex justify-between w-full items-center flex-wrap-reverse gap-10 h-screen sm:px-0 px-5 relative pt-[72px] xl:pt-0">
          {currentComponent}
          <div className="w-[430px] hidden sm:block">
            <QuizIcon />
            <p className="text-base">
              <b>{layoutLang[String(locale)]["tanlash_qiyinmi"]}</b>
              <Link
                href={"/gorod-tashkent/novostroyki"}
                className="text-[#0DC683]"
              >
                «Uysot»
              </Link>
              {layoutLang[String(locale)]["mos_uy"]}
            </p>
          </div>
          <div className="absolute top-6 left-5 sm:left-0">
            <Link
              href={"/gorod-tashkent/novostroyki"}
              className="cursor-pointer flex items-center gap-5"
            >
              <JKArrowLeftIcon fill="#475467" />
              <UysotIcon />
            </Link>
          </div>
        </div>
      ) : (
        <div className="max-w-[1230px] mx-auto h-full w-full flex items-center relative">
          <div className="absolute top-6 left-5 sm:left-0 flex items-center gap-5">
            <Link
              href={"/gorod-tashkent/novostroyki"}
              className="cursor-pointer"
            >
              <JKArrowLeftIcon fill="#475467" />
            </Link>
            <UysotIcon />
          </div>
          <div className="flex flex-col gap-6 w-full pt-[130px] px-5 sm:px-0 pb-5 sm:pb-0 min-h-screen">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#344054] text-2xl font-medium">
                {layoutLang[String(locale)]["siz_bilan_boglanamiz"]}
              </h1>
              <p className="text-[#98A2B3] text-base font-medium">
                {layoutLang[String(locale)]["uysotdan_taklif"]}
              </p>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-4 w-full flex-wrap">
              {quizData?.slice(0, 3)?.map((item) => (
                <NonSSRWrapper key={item?.id}>
                  <ComplexCard
                    data={item}
                    key={item?.id}
                    viewType={viewTypes.GRID}
                  />
                </NonSSRWrapper>
              ))}
            </div>
            <div className="flex items-center justify-center">
              <Link
                href={moreLink}
                className="border border-[#ebebeb] border-solid h-[43px] flex items-center justify-center px-8 rounded-md text-[#0dc683] text-base font-medium"
              >
                {layoutLang[String(locale)]["Hammasi"]}
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Quiz
