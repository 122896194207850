import { useMutation } from "@tanstack/react-query"
import { useAppDispatch } from "../../../hooks/reduxHooks"
import $api from "../../../services/RequestService"
import { favouritesReducerActions } from "../../../store/reducers/favouritesReducer"
import { userReducerActions } from "../../../store/reducers/userReducer"
import { localStorageKeys } from "../../../utils/constants/localStorageKeys"
import { messageTypes } from "../../../utils/constants/messageTypes"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { showMessage } from "../../../utils/helpers/showMessage"
import { ErrorRes, SuccessRes } from "../../../utils/models/responseType"
import { endpoints } from "../utils/constants/apis"
import { useRouter } from "next/router"
import { message } from "antd"
import { layoutLang } from "utils/language/layout"

// register
export function useRegister(afterSuccess: () => void) {
  const { locale } = useRouter()
  return useMutation<any, ErrorRes, { phone: string }>(
    async (req) => {
      const res = await $api.post(endpoints.REGISTER, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(
          res?.message[locale as keyof typeof res.message],
          messageTypes.SUCCESS
        )
        afterSuccess()
      },
      onError: errorHandler,
    }
  )
}

// login
export function useLogin(afterSuccess: () => void) {
  const dispatch = useAppDispatch()
  const { setIsAuth } = userReducerActions

  return useMutation<
    SuccessRes<{ phone: string }>,
    ErrorRes,
    { phone: string; sms_code: number; bonus_id?: number; complex_id?: number }
  >(
    async (req) => {
      const res = await $api.post(endpoints.LOGIN, req)
      return res.data.data
    },
    {
      onSuccess: (res) => {
        localStorage.setItem(localStorageKeys.PHONE, res?.phone)
        dispatch(setIsAuth(true))
        afterSuccess()
      },
      onError: errorHandler,
    }
  )
}

// logout
export function useLogout() {
  const dispatch = useAppDispatch()
  const { setIsAuth } = userReducerActions
  const { clearFavourites } = favouritesReducerActions

  return useMutation(
    async () => {
      const res = await $api.get(endpoints.LOGOUT)
      return res.data
    },
    {
      onSuccess: () => {
        dispatch(setIsAuth(false))
        dispatch(clearFavourites())
      },
      onError: errorHandler,
    }
  )
}

export function useGiveUserFeedback(locale: string, onAfterFunc?: () => void) {
  return useMutation<
    any,
    Error,
    { rating: number; comment: string; url: string }
  >({
    mutationFn: async (data) => {
      const res = await $api.post(endpoints.USER_FEEDBACK, data)
      return res.data
    },
    onSuccess: () => {
      onAfterFunc && onAfterFunc()
      message.success(layoutLang[String(locale)]["user_feedback_message"])
    },
  })
}
