import React, { useEffect, useState } from "react"
import { isAndroid, isMobile } from "react-device-detect"
import Link from "next/link"
import { useRouter } from "next/router"

import ArrowRightIcon from "../../../../assets/icons/ArrowRightIcon"
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks"
import { localeReducerActions } from "../../../../store/reducers/localeReducer"
import { userReducerActions } from "../../../../store/reducers/userReducer"
import { RU, UZ } from "../../../../utils/constants/locales"
import { menuLinks } from "../../utils/constants/menuLinks"

import styles from "./mobileMenu.module.scss"
import { layoutLang } from "../../../../utils/language/layout"
import { cx } from "utils/cx"
import CurrencyIcon from "assets/icons/CurrencyIcon"
import MenuLocationIcon from "assets/icons/MenuLocationIcon"
import LoginModal from "../login-modal/LoginModal"
import YouTubeIcon from "assets/icons/YouTubeIcon"
import LinkedinIcon from "assets/icons/LinkedinIcon"
import MenuTelegramIcon from "assets/icons/MenuTelegramIcon"
import MenuInstagrammIcon from "assets/icons/MenuInstagrammIcon"
import MenuFacebookIcon from "assets/icons/MenuFacebookIcon"
import CloseIcon from "assets/icons/CloseIcon"
import { isBrowser } from "utils/helpers/isBrowser"
import UserIcon from "assets/icons/UserIcon"
import { Button } from "antd"
import { useLogout } from "pages/main-layout/services/mutations"
import { favouritesReducerActions } from "store/reducers/favouritesReducer"

export const sotialItems = [
  {
    id: 4,
    icon: YouTubeIcon,
    link: "https://www.youtube.com/@uysot_uz",
    platform: null,
  },
  {
    id: 5,
    icon: LinkedinIcon,
    link: "https://www.linkedin.com/company/uysot/",
    platform: null,
  },
  {
    id: 3,
    icon: MenuFacebookIcon,
    link: "https://www.facebook.com/uysot.uz",
    platform: "facebook",
    shareUrl: "https://www.facebook.com/sharer/sharer.php?u=",
  },
  {
    id: 1,
    icon: MenuTelegramIcon,
    link: "https://t.me/uysot_uz",
    platform: "telegram",
    shareUrl: "https://telegram.me/share/url?url=",
  },
  {
    id: 2,
    icon: MenuInstagrammIcon,
    link: "https://www.instagram.com/uysot_uz/",
    platform: "instagram",
    shareUrl: "https://www.instagram.com/?url=",
  },
]

const MobileMenu: React.FC = () => {
  const dispatch = useAppDispatch()
  const { visibleMobileMenu, userAddress, menuIcon } = useAppSelector(
    (state) => state.userReducer
  )
  const [isAppSuggestionCanceled, setIsAppSuggestionCanceled] = useState(false)
  const { currency } = useAppSelector((state) => state.localeReducer)

  const { locale } = useRouter()
  const { changeLanguage } = localeReducerActions
  const { setOpenRegionSelect, setCurrentRegion, setChangeFilterType } =
    favouritesReducerActions
  const { location: currentLocation } = useAppSelector(
    (state) => state.favouritesReducer
  )
  const {
    setVisibleLoginModal,
    setOpenCurrency,
    setVisibleMobileMenu,
    setChangeMenu,
  } = userReducerActions

  const city_id = currentLocation?.regionValue
    ? currentLocation?.regionValue?.city?.id
      ? currentLocation?.regionValue?.city?.id
      : currentLocation?.regionValue?.district?.city?.id
    : undefined

  const district_id =
    currentLocation?.regionValue && currentLocation?.regionValue?.district
      ? currentLocation?.regionValue?.district?.id
      : undefined

  const location =
    currentLocation?.regionValue && currentLocation?.regionValue?.city
      ? currentLocation?.regionValue?.city?.url
      : currentLocation?.regionValue?.district?.city?.url || "gorod-tashkent"

  const isAuth = isBrowser() && localStorage.getItem("is_auth")
  const phone = isBrowser() && localStorage.getItem("phone")
  const logout = useLogout()

  useEffect(() => {
    if (isBrowser() && localStorage.getItem("currentRegion")) {
      dispatch(
        setCurrentRegion(JSON.parse(localStorage.getItem("currentRegion")!))
      )
    }
  }, [])

  // close mobile menu
  const handleCloseMobileMenu = () => {
    dispatch(setVisibleMobileMenu(false))
    dispatch(setChangeMenu(!menuIcon))
  }

  useEffect(() => {
    dispatch(changeLanguage(locale === RU ? RU : UZ))
  }, [])

  const updateAppSuggestionStatus = () => {
    const appSuggestion = localStorage.getItem("appSuggestionCancelledAt")
    setIsAppSuggestionCanceled(!appSuggestion)
  }

  useEffect(() => {
    window.addEventListener("storage", updateAppSuggestionStatus)

    const intervalId = setInterval(updateAppSuggestionStatus, 500)

    return () => {
      window.removeEventListener("storage", updateAppSuggestionStatus)
      clearInterval(intervalId)
    }
  }, [])

  const handleOpen = () => {
    dispatch(setVisibleLoginModal(true))
  }

  const handleLogout = () => {
    logout.mutate()
    handleCloseMobileMenu()
  }

  return (
    <div
      className={cx(
        `${styles.mobile_menu} ${visibleMobileMenu ? styles.active : ""} ${userAddress ? styles.has_address : ""
        }`,
        isAndroid && isMobile && isAppSuggestionCanceled
          ? styles.mobile_menu_with_app_suggestion
          : ""
      )}
    >
      <div className={`${styles.content} bg-[#f2f4f6] relative`}>
        <div className="flex flex-col items-center gap-6 pt-6 relative">
          <div
            className="w-[30px] h-[30px] rounded-full flex items-center justify-center absolute bg-white right-0 top-[-2px]"
            onClick={handleCloseMobileMenu}
          >
            <CloseIcon />
          </div>
          {isAuth === "true" ? (
            <div className="flex flex-col gap-4 items-center">
              <UserIcon />
              <span className="text-[#344054] text-lg font-semibold">
                {phone}
              </span>
            </div>
          ) : (
            <div className="w-full items-center gap-7 flex flex-col">
              <div className="flex items-center justify-center relative w-full pt-5">
                <h2 className="text-[#2C2C2C] font-bold text-xl">
                  {layoutLang[String(locale)]["Profilingizga kiring"]}
                </h2>
              </div>
              <div
                className="bg-[#0DC683] h-[46px] rounded-xl py-[14px] flex items-center justify-center text-[15px] text-white font-semibold w-full cursor-pointer"
                onClick={handleOpen}
              >
                {layoutLang[String(locale)]["Kirish"]}
              </div>
            </div>
          )}
          <div className="flex items-center gap-2 w-full">
            <div
              className="w-[40%] bg-white flex justify-between items-center p-3 rounded-xl cursor-pointer"
              onClick={() => dispatch(setOpenCurrency(true))}
            >
              <div className="flex flex-col gap-0.5">
                <p className="text-[#344054] text-base font-medium">
                  {layoutLang[String(locale)]["Valyuta"]}
                </p>
                <span className="text-[#667085] text-sm">
                  {currency?.toUpperCase()}
                </span>
              </div>
              <CurrencyIcon />
            </div>
            <div
              className="w-[60%] bg-white flex justify-between items-center p-3 rounded-xl cursor-pointer h-[70px]"
              onClick={() =>
                dispatch(setOpenRegionSelect({ type: "location", value: true }))
              }
            >
              <div className="flex flex-col gap-0.5">
                {currentLocation?.regionValue?.city ||
                  currentLocation?.regionValue?.district ? (
                  !currentLocation?.regionValue?.city ? (
                    <div>
                      <p className="text-[#344054] text-base font-medium">
                        {
                          currentLocation?.regionValue?.district?.name[
                          locale as keyof typeof currentLocation.regionValue.district.name
                          ]
                        }
                      </p>
                      <span className="text-[#667085] text-sm">
                        {
                          currentLocation?.regionValue?.district?.city?.name[
                          locale as keyof typeof currentLocation.regionValue.district.city.name
                          ]
                        }
                      </span>
                    </div>
                  ) : (
                    <div className="text-[#344054] text-base font-medium">
                      {
                        currentLocation?.regionValue?.city?.name[
                        locale as keyof typeof currentLocation.regionValue.city.name
                        ]
                      }
                    </div>
                  )
                ) : (
                  <div className="text-[#344054] text-base font-medium">
                    {layoutLang[String(locale)]["mintaqa"]}
                  </div>
                )}
              </div>
              {currentLocation?.regionValue ? (
                <div
                  className="w-6 h-6 rounded-full flex items-center justify-center bg-[#D0D5DD] cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation()
                    dispatch(
                      setCurrentRegion({ type: "location", value: { city: null, district: null } })
                    )
                    localStorage.removeItem("currentRegion")
                  }}
                >
                  <CloseIcon size={16} />
                </div>
              ) : (
                <MenuLocationIcon />
              )}
            </div>
          </div>
        </div>

        <div
          className={`${styles.content_body} ${visibleMobileMenu ? styles.active : ""
            } bg-white rounded-xl overflow-hidden p-3 pb-0`}
        >
          <nav>
            <ul className={styles.menu}>
              {menuLinks(location, city_id, district_id).map((item, index) => (
                <li key={index}>
                  <Link href={item.path} legacyBehavior>
                    <a
                      onClick={() => {
                        handleCloseMobileMenu()
                        item?.path?.includes("kvartiri")
                          ? dispatch(setChangeFilterType("apartment"))
                          : item?.path?.includes("novostroyki")
                            ? dispatch(setChangeFilterType("complex"))
                            : ""
                      }}
                      aria-hidden
                    >
                      <span>
                        {item.label === "EXPO 2024"
                          ? item.label
                          : layoutLang[String(locale) as any][item.label]}
                      </span>
                      <ArrowRightIcon />
                    </a>
                  </Link>
                </li>
              ))}
              {isAuth === "true" && (
                <Link href={"/bonuses"} legacyBehavior>
                  <a onClick={handleCloseMobileMenu} aria-hidden>
                    <span>{layoutLang[String(locale)]["Bonuslar"]}</span>
                    <ArrowRightIcon />
                  </a>
                </Link>
              )}
            </ul>
          </nav>
        </div>

        <div className="p-3 flex flex-col gap-3 rounded-xl bg-white">
          <span className="text-[#344054] text-sm font-medium">
            {layoutLang[String(locale)]["Ijtimoiy tarmoqlar"]}
          </span>
          <div className="flex items-center justify-between">
            {sotialItems?.map((item, index) => (
              <a
                className="w-[58px] h-[58px] bg-[#F2F4F7] rounded-full flex items-center justify-center"
                href={item?.link}
                // legacyBehavior
                target="_blank"
                key={index}
              >
                {item?.icon()}
              </a>
            ))}
          </div>
        </div>
        {isAuth === "true" && (
          <Button
            className="h-[46px] rounded-xl !text-[#344054] font-semibold border-none"
            onClick={handleLogout}
            loading={logout.isLoading}
          >
            {layoutLang[String(locale)]["Chiqish"]}
          </Button>
        )}
      </div>
      <LoginModal />
    </div>
  )
}

export default MobileMenu
