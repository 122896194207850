"use-client"
import UserFeedbackModal from "pages/main-layout/components/user-feedback-modal"
import React, { ReactNode, useEffect, useState } from "react"
import { Grid } from "antd"
import { useRouter } from "next/router"
import { isAndroid, isMobile } from "react-device-detect"

import NonSSRWrapper from "../../common/non-ssr/NonSSRWrapper"
import { useAppSelector } from "../../hooks/reduxHooks"
import Footer from "./components/footer/Footer"
import Header from "./components/header/Header"
import LoginModal from "./components/login-modal/LoginModal"
import MobileAppDownload from "./components/mobile-app-download/MobileAppDownload"
import MobileMenu from "./components/mobile-menu/MobileMenu"

import { isBrowser } from "utils/helpers/isBrowser"
import { useCheckHasToken } from "services/useCheckHasToken"
import FooterFilter from "./components/footer-filter/FooterFilter"
import MobileFooterMenu from "./components/mobile-footer-menu/MobileFooterMenu"

import styles from "./index.module.scss"
import LangDropdown from "./components/header/bottom/LangDropdown"
import MenuCurrency from "./components/mobile-menu/menu-currency/MenuCurrency"
import NavigateButton from "./components/navigate-button/NavigateButton"
import RegionCity from "./components/region-city/RegionCity"

type Props = {
  children: ReactNode
}

const MainLayout: React.FC<Props> = ({ children }) => {
  const router = useRouter()
  const { md, lg, sm, xs } = Grid.useBreakpoint()
  const { userAddress } = useAppSelector((state) => state.userReducer)
  const [isAppSuggestionCanceled, setIsAppSuggestionCanceled] = useState(false)

  const hasToken = isBrowser() ? localStorage.getItem("token") : ""
  const getAnonimUserId = useCheckHasToken()

  useEffect(() => {
    if (!hasToken) {
      getAnonimUserId.mutateAsync().then((data) => {
        localStorage.setItem("anonymous_user_id", data?.data?.anonymous_user_id)
      })
    }
  }, [hasToken])

  const updateAppSuggestionStatus = () => {
    const appSuggestion = localStorage.getItem("appSuggestionCancelledAt")
    setIsAppSuggestionCanceled(!appSuggestion)
  }

  useEffect(() => {
    window.addEventListener("storage", updateAppSuggestionStatus)

    const intervalId = setInterval(updateAppSuggestionStatus, 500)

    return () => {
      window.removeEventListener("storage", updateAppSuggestionStatus)
      clearInterval(intervalId)
    }
  }, [])

  const inNavigateBtn =
    (router.pathname.includes("karta") ||
      router.pathname.includes("kvartiri") ||
      router.pathname.includes("novostroyki")) &&
    !router?.query?.apartmentId &&
    !router?.query?.id

  const isPad =
    router?.pathname === "/[location]/kvartiri" ||
    router?.pathname === "/[location]/novostroyki"

  const header = () => {
    if (lg || md || sm || xs) {
      if (
        xs &&
        (router?.query?.id ||
          router?.query?.builderId ||
          router?.query?.apartmentId)
      ) {
        return null
      }
      return (
        <header className={styles.layout_header} id="app_bar">
          <Header />
        </header>
      )
    }

    return null
  }
  const headerTop = () => {
    if (lg || md || sm || xs) {
      if (
        xs &&
        (router?.query?.id ||
          router?.query?.builderId ||
          router?.query?.apartmentId)
      ) {
        return null
      }
      return (
        <div
          className={`${styles.distance} ${userAddress ? styles.has_address : ""
            } ${isPad ? "md:pb-[120px] pb-[70px]" : "pb-[70px]"} removed_item`}
        />
      )
    }

    return null
  }

  return (
    <>
      <div className={styles.layout} id="layout">
        {headerTop()}
        {header()}

        <NonSSRWrapper>
          <MobileMenu />
        </NonSSRWrapper>
        <main
          className={
            isAppSuggestionCanceled && !router?.query?.id && !router?.query?.apartmentId && !router?.query?.builderId && isAndroid && isMobile
              ? styles.layout_children_with_app_suggestion
              : styles.layout_children
          }
        >
          {children}
        </main>

        <div className="removed_item">
          {/* <Notification /> */}
          <NonSSRWrapper>
            <LangDropdown />
          </NonSSRWrapper>
          <NonSSRWrapper>
            <MenuCurrency />
          </NonSSRWrapper>
          <FooterFilter />
          <MobileAppDownload />
          <Footer />
          {inNavigateBtn && (
            <NonSSRWrapper>
              <div className="mobile_only">
                <NavigateButton />
              </div>
            </NonSSRWrapper>
          )}
          <NonSSRWrapper>
            <MobileFooterMenu />
          </NonSSRWrapper>
        </div>
      </div>
      <LoginModal />
      <NonSSRWrapper>
        <RegionCity />
      </NonSSRWrapper>
      <NonSSRWrapper>
        <UserFeedbackModal />
      </NonSSRWrapper>
    </>
  )
}

export default MainLayout
