import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  FilterComplexType,
  FilterNearestPlasesType,
  MetroLinesType,
} from "pages/main-layout/components/region-city/utils/models/ComplexFilterModel"
import { sliceNames } from "utils/constants/sliceNames"

type initialState = {
  complexes: FilterComplexType[] | undefined
  metro_lines: MetroLinesType[] | undefined
  nearest_places: FilterNearestPlasesType[] | undefined
}

const initialState: initialState = {
  complexes: undefined,
  metro_lines: undefined,
  nearest_places: undefined,
}

const filterReducer = createSlice({
  name: sliceNames.FILTER,
  initialState: initialState,
  reducers: {
    setComplexes: (state, action: PayloadAction<FilterComplexType[]>) => {
      state.complexes = action.payload
    },
    setMetroLines: (state, action: PayloadAction<MetroLinesType[]>) => {
      state.metro_lines = action.payload
    },
    setNearestPlaces: (
      state,
      action: PayloadAction<FilterNearestPlasesType[]>
    ) => {
      state.nearest_places = action.payload
    },
    clearPlaceFilters: (state) => {
      state.complexes = []
      state.metro_lines = []
      state.nearest_places = []
    },
  },
})

export default filterReducer.reducer

export const filterReducerActions = filterReducer.actions
