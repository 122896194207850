import React from "react"

const MenuInstagrammIcon = () => {
  return (
    <svg
      width="35"
      height="34"
      viewBox="0 0 35 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8388 0H17.7613C8.39388 0 0.800049 7.59606 0.800049 16.9663V17.0437C0.800049 26.4139 8.39388 34.01 17.7613 34.01H17.8388C27.2062 34.01 34.8 26.4139 34.8 17.0437V16.9663C34.8 7.59606 27.2062 0 17.8388 0Z"
        fill="url(#paint0_linear_9454_9293)"
      />
      <path
        d="M23.0602 6.91943H12.5397C9.63318 6.91943 7.26868 9.28463 7.26868 12.192V21.8196C7.26868 24.7269 9.63318 27.0921 12.5397 27.0921H23.0602C25.9667 27.0921 28.3312 24.7269 28.3312 21.8196V12.192C28.3312 9.28463 25.9667 6.91943 23.0602 6.91943ZM9.12812 12.192C9.12812 10.3105 10.6588 8.77942 12.5397 8.77942H23.0602C24.9411 8.77942 26.4718 10.3105 26.4718 12.192V21.8196C26.4718 23.701 24.9411 25.2322 23.0602 25.2322H12.5397C10.6588 25.2322 9.12812 23.701 9.12812 21.8196V12.192Z"
        fill="white"
      />
      <path
        d="M17.799 21.9078C20.5018 21.9078 22.702 19.7083 22.702 17.0035C22.702 14.2987 20.503 12.0991 17.799 12.0991C15.0951 12.0991 12.8961 14.2987 12.8961 17.0035C12.8961 19.7083 15.0951 21.9078 17.799 21.9078ZM17.799 13.9603C19.4774 13.9603 20.8425 15.3258 20.8425 17.0047C20.8425 18.6835 19.4774 20.049 17.799 20.049C16.1207 20.049 14.7556 18.6835 14.7556 17.0047C14.7556 15.3258 16.1207 13.9603 17.799 13.9603Z"
        fill="white"
      />
      <path
        d="M23.1563 12.8922C23.8842 12.8922 24.4774 12.3 24.4774 11.5708C24.4774 10.8416 23.8854 10.2494 23.1563 10.2494C22.4273 10.2494 21.8353 10.8416 21.8353 11.5708C21.8353 12.3 22.4273 12.8922 23.1563 12.8922Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9454_9293"
          x1="5.76729"
          y1="29.0413"
          x2="29.8399"
          y2="4.97699"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#FAAD4F" />
          <stop offset="0.35" stopColor="#DD2A7B" />
          <stop offset="0.62" stopColor="#9537B0" />
          <stop offset="1" stopColor="#515BD4" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default MenuInstagrammIcon
