import React from "react"
import MenuLocationIcon from "assets/icons/MenuLocationIcon"
import RegCheckIcon from "assets/icons/RegCheckIcon"
import { Spin } from "antd"
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks"
import { useRouter } from "next/router"
import { useGetAllRegion } from "pages/apartments/services/queries"
import { favouritesReducerActions } from "store/reducers/favouritesReducer"
import { layoutLang } from "utils/language/layout"

const RegionSearch = () => {
  const dispatch = useAppDispatch()
  const { location, locationType, hotOffersRegionValue } = useAppSelector(
    (state) => state.favouritesReducer
  )
  const { data, isLoading } = useGetAllRegion(location?.searchValue)

  const { setRegionId, setDistrictId, setOpenRegionSelect, setCurrentRegion } =
    favouritesReducerActions
  const { locale } = useRouter()

  const currentLoc =
    locationType === "hot_location"
      ? hotOffersRegionValue
      : location?.regionValue
  return (
    <div
      className="flex flex-col gap-3 p-3 rounded-xl bg-white mobileTopFilter"
      style={{ height: "calc(100vh - 130px)", overflow: "auto" }}
    >
      <Spin spinning={isLoading}>
        <div className="flex flex-col gap-3">
          <div
            className={`flex items-center gap-3 px-1 pb-3 border-b border-solid border-[#EAECF0] cursor-pointer`}
            onClick={() => {
              dispatch(setRegionId(null))
              dispatch(setDistrictId(null))
              dispatch(setOpenRegionSelect({ type: undefined, value: false }))
              dispatch(
                setCurrentRegion({ type: locationType, value: {city: null, district: null} })
              )
              localStorage.removeItem("currentRegion")
            }}
          >
            <MenuLocationIcon />
            <div className="flex flex-col">
              <span className="text-[#344054] font-medium">
                {layoutLang[String(locale)]["Butun_Ozbekiston_boylab"]}
              </span>
            </div>
          </div>
          {data?.map((item, index) => (
            <div key={index}>
              {item?.district ? (
                <div
                  className={`flex items-center gap-3 px-1 pb-3 ${
                    index === data?.length - 1 ? "border-none" : "border-b"
                  } border-solid border-[#EAECF0] cursor-pointer`}
                  onClick={() => {
                    item?.district?.city?.id &&
                      dispatch(setRegionId(item?.district?.city?.id))
                    item?.district?.id &&
                      dispatch(setDistrictId(item?.district?.id))
                    dispatch(
                      setOpenRegionSelect({ type: undefined, value: false })
                    )
                    dispatch(
                      setCurrentRegion({ type: locationType, value: item })
                    )

                    locationType !== "hot_location" &&
                      localStorage.setItem(
                        "currentRegion",
                        JSON.stringify(item)
                      )
                    // locationType === "hot_location" &&
                    //   push({
                    //     query: {
                    //       city_id: item?.district?.city?.id,
                    //       district_id: item?.district?.id,
                    //     },
                    //   })
                  }}
                >
                  <MenuLocationIcon />
                  <div className="flex flex-col">
                    <span className="text-[#344054] font-medium">
                      {
                        item?.district?.name?.[
                          locale as keyof typeof item.district.name
                        ]
                      }
                    </span>
                    <span className="text-[#667085] text-xs">
                      {
                        item?.district?.city?.name[
                          locale as keyof typeof item.district.city.name
                        ]
                      }
                    </span>
                  </div>
                  {currentLoc?.district?.id === item?.district?.id && (
                    <div className="absolute right-5">
                      <RegCheckIcon />
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className={`flex items-center gap-3 px-1 pb-3 ${
                    index === data?.length - 1 ? "border-none" : "border-b"
                  } border-solid border-[#EAECF0] cursor-pointer`}
                  onClick={() => {
                    item?.city?.id && dispatch(setRegionId(item?.city?.id))
                    dispatch(setDistrictId(null))
                    dispatch(
                      setOpenRegionSelect({ type: undefined, value: false })
                    )
                    dispatch(
                      setCurrentRegion({ type: locationType, value: item })
                    )

                    locationType !== "hot_location" &&
                      localStorage.setItem(
                        "currentRegion",
                        JSON.stringify(item)
                      )
                    // locationType === "hot_location" &&
                    //   push({
                    //     query: {
                    //       city_id: item?.city?.id,
                    //     },
                    //   })
                  }}
                >
                  <MenuLocationIcon />
                  <span>
                    {item?.city?.name[locale as keyof typeof item.city.name]}
                  </span>
                  {currentLoc?.city?.id === item?.city?.id && (
                    <div className="absolute right-5">
                      <RegCheckIcon />
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </Spin>
    </div>
  )
}

export default RegionSearch
