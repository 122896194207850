import React from 'react';

const SecondSmileIcon: React.FC = () => {
 return (
   <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
     <path
       d="M19.0008 2.00016C28.2004 1.95993 36.0263 9.42511 35.9999 19.0529C35.9743 28.4442 28.3829 36.0326 18.9408 35.9999C9.42816 35.9678 2.00227 28.2417 2 19.047C1.9975 9.59672 9.57044 2.00016 19.0008 2.00016Z"
       fill="#FECA00" />
     <path
       d="M23.7222 19.9263C23.7219 19.5549 23.7953 19.1871 23.9382 18.8442C24.0811 18.5013 24.2907 18.1899 24.5549 17.9282C24.8191 17.6665 25.1327 17.4595 25.4775 17.3193C25.8224 17.1791 26.1917 17.1083 26.5641 17.1112C28.1212 17.1165 29.3889 18.379 29.3889 19.9244C29.3889 21.5086 28.1291 22.7759 26.5533 22.7777C24.9775 22.7796 23.7216 21.515 23.7222 19.9263Z"
       fill="#171615" />
     <path
       d="M14.2778 19.9457C14.2765 21.5072 13.0097 22.7765 11.4511 22.7778C9.89244 22.779 8.61422 21.5049 8.61112 19.9523C8.60802 18.3837 9.88397 17.1094 11.456 17.1111C13.0159 17.1148 14.2796 18.3829 14.2778 19.9457Z"
       fill="#171615" />
     <path
       d="M21.147 23.7263C21.4937 23.7065 21.8847 23.7615 22.2725 23.8405C22.6176 23.9109 22.7867 24.092 22.7774 24.372C22.7683 24.6415 22.6027 24.807 22.2596 24.8497C20.7016 25.0435 19.2611 25.5284 17.9977 26.4686C17.3267 26.9686 16.7374 27.55 16.1852 28.1713C15.9398 28.4474 15.712 28.5089 15.4728 28.3796C15.2336 28.2504 15.1622 28.0164 15.2735 27.6743C15.8009 26.0704 16.9226 24.9961 18.4448 24.2922C19.3137 23.8912 20.15 23.7263 21.147 23.7263Z"
       fill="#171615" />
     <path
       d="M8.61111 14.0914C8.61386 12.5353 9.548 11.0076 10.7537 10.5889C10.9735 10.5125 11.2147 10.3953 11.3806 10.6884C11.5465 10.9816 11.3544 11.1854 11.1991 11.3823C10.4071 12.3873 9.79596 13.5133 9.46267 14.8251C9.40223 15.0632 9.28631 15.2339 9.05319 15.2216C8.82006 15.2094 8.72136 15.0315 8.67942 14.7835C8.64115 14.5546 8.61148 14.3252 8.61111 14.0914Z"
       fill="#171615" />
     <path
       d="M26.8098 16.1643C26.1971 16.1603 25.6116 16.0295 25.0719 15.697C24.8355 15.5512 24.6107 15.3957 24.6791 15.0399C24.7476 14.6841 25.017 14.6684 25.271 14.6511C25.992 14.6029 26.7077 14.5206 27.4131 14.3426C28.1744 14.1506 28.8796 13.803 29.5738 13.4252C29.8116 13.2957 30.0293 13.2847 30.2112 13.5122C30.3932 13.7398 30.3536 13.9825 30.2112 14.2186C29.5489 15.3147 28.5933 15.8972 27.4294 16.1143C27.315 16.1358 27.1973 16.1525 27.0806 16.1628C26.9904 16.1705 26.8998 16.1643 26.8098 16.1643Z"
       fill="#171615" />
   </svg>

 );
}

export default SecondSmileIcon;