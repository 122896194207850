import { Button } from "antd"
import JKArrowLeftIcon from "assets/icons/JKArrowLeftIcon"
import { useRouter } from "next/router"
import React from "react"
import { layoutLang } from "utils/language/layout"
import { minPrices } from "../utils/constants/quizConstants"
import { itemStyle } from "../Quiz"
import { QuizStateModel } from "../utils/models/quizStateModel"

interface IProps {
  quizState: QuizStateModel
  setQuizState: React.Dispatch<React.SetStateAction<QuizStateModel>>
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
}

const QuizComponent5: React.FC<IProps> = ({
  page,
  quizState,
  setPage,
  setQuizState,
}) => {
  const { locale } = useRouter()
  return (
    <div className="sm:w-[600px] w-full sm:h-auto h-full flex flex-col justify-between pt-8 pb-16 sm:py-0">
      <div className="min-h-[305px]">
        <p className="text-[#98A2B3] text-base font-medium mb-2">{`${page}/${
          quizState?.payment_method_id === 2 ? 6 : 5
        } ${layoutLang[String(locale)]["savol"]}`}</p>
        <h2 className="text-[#344054] text-2xl font-medium">
          {layoutLang[String(locale)]["oylik_tolov_oraligi"]}
        </h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-5">
          {minPrices?.map((item) => {
            return (
              <div
                key={item?.id}
                className={`${
                  item?.value === quizState?.monthly_amount
                    ? "bg-[#EEFBF6]"
                    : ""
                } ${
                  item?.value === quizState?.monthly_amount
                    ? "!border-[#0DC683]"
                    : ""
                } ${itemStyle}`}
                onClick={() =>
                  item?.value === quizState?.monthly_amount
                    ? setQuizState((state) => ({
                        ...state,
                        monthly_amount: "",
                      }))
                    : setQuizState((state) => ({
                        ...state,
                        monthly_amount: item?.value,
                      }))
                }
              >
                {`${locale === "ru" && item?.value === "5" ? "до" : ""} ${
                  item?.value
                } ${
                  item?.id === 5 && quizState?.payment_method_id !== 2
                    ? ""
                    : layoutLang[String(locale)][item?.type]
                } ${locale === "uz" && item?.value === "5" ? "gacha" : ""}`}
              </div>
            )
          })}
        </div>
      </div>
      <div className="flex items-center gap-3 w-full mt-[68px]">
        <div
          onClick={() => setPage(page - 1)}
          className="h-[47px] flex items-center justify-center rounded-xl bg-[#F2F4F7] gap-2 cursor-pointer px-5 select-none text-base"
        >
          <JKArrowLeftIcon fill="#475467" width={18} height={14} />
          <span className="text-base text-[#475467] font-medium">
            {layoutLang[String(locale)]["ortga"]}
          </span>
        </div>
        <Button
          type="primary"
          onClick={() => setPage(page + 1)}
          className="w-full h-[47px] rounded-xl flex items-center justify-center text-base"
          disabled={!quizState?.monthly_amount}
        >
          {layoutLang[String(locale)]["keyingisi"]}
        </Button>
      </div>
    </div>
  )
}

export default QuizComponent5
