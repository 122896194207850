export const makeUrl = (string: string) => {
  return string
    ?.trim()
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&apos;")
    .replace(/\s+/g, "-")
    .replace(/["'`+,.\s‘?!()]/g, "")
    .toLowerCase()
}
