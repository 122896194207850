import dayjs from "dayjs"
import Link from "next/link"
import React from "react"

import InstagramIcon from "../../../../assets/icons/InstagramIcon"
import TelegramIcon from "../../../../assets/icons/TelegramIcon"

import style from "./Footer.module.scss"
import { layoutLang } from "../../../../utils/language/layout"
import { useRouter } from "next/router"

const year = dayjs().year()

function Footer() {
  const { locale } = useRouter()

  return (
    <footer className={style.footer}>
      <div className="wrapper">
        <div className={style.footer_top}>
          <div className={style.footer_top_links}>
            <Link
              href={
                "https://business.uysot.uz/?utm_source=uysot_market&utm_medium=site&utm_campaign=promo"
              }
              legacyBehavior
            >
              <a target="_blank">
                {layoutLang[String(locale)]["Для застройщиков"]}
              </a>
            </Link>
            <Link href={"/faq"} legacyBehavior>
              <a>{layoutLang[String(locale)].Tez_tez_beriladigan_savollar}</a>
            </Link>
            <Link
              href={
                "https://docs.google.com/document/d/152DlTJXqCBVfZQFmaKGWBden5-hzEXugRbwWSP92VaA"
              }
              legacyBehavior
            >
              <a target="_blank">
                {layoutLang[String(locale)]["Foydalanish shartlari"]}
              </a>
            </Link>
          </div>
          <div className={style.footer_top_messenger}>
            <Link href={"https://t.me/uysot_uz"} legacyBehavior>
              <a target="_blank" aria-label="telegram url">
                <TelegramIcon />
              </a>
            </Link>
            <Link href={"https://www.instagram.com/uysot_uz/"} legacyBehavior>
              <a target="_blank" aria-label="instagram url">
                <InstagramIcon />
              </a>
            </Link>
          </div>
        </div>
        <div className={style.footer_bottom}>
          <span>© {year} «Uysot»</span>
          <a href="tel:+998781136350">+998 78 113 63 50</a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
