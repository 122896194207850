import React from "react"
import { useGetComplexFilteres } from "../../services/queries"
import Complex1Icon from "assets/icons/Complex1Icon"
import { useRouter } from "next/router"
import LocomotiveIcon from "assets/icons/LocomotiveIcon"
import { isEmptyArr } from "utils/helpers/isEmptyArr"
import { Button, Spin } from "antd"
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks"
import { filterReducerActions } from "store/reducers/filterReducer"
import {
  FilterComplexType,
  FilterNearestPlasesType,
  MetroLinesType,
} from "../../utils/models/ComplexFilterModel"
import RegCheckIcon from "assets/icons/RegCheckIcon"
import { layoutLang } from "utils/language/layout"
import { favouritesReducerActions } from "store/reducers/favouritesReducer"

const MetroSearch = () => {
  const dispatch = useAppDispatch()
  const { locale } = useRouter()
  const { complexes, metro_lines, nearest_places } = useAppSelector(
    (state) => state.filterReducer
  )
  const searchValue = useAppSelector(
    (state) => state.favouritesReducer.location.searchValue
  )
  const { setComplexes, setMetroLines, setNearestPlaces } = filterReducerActions
  const { setOpenRegionSelect } = favouritesReducerActions
  const { data, isLoading } = useGetComplexFilteres(searchValue)

  const handleSetComplexes = (value: FilterComplexType) => {
    if (complexes) {
      if (complexes?.some((item) => item?.id === value?.id)) {
        const newData = complexes?.filter((item) => item?.id !== value?.id)
        dispatch(setComplexes(newData))
      } else {
        dispatch(setComplexes([...complexes, value]))
      }
    } else {
      dispatch(setComplexes([value]))
    }
  }
  const handleSetMetroLines = (value: MetroLinesType) => {
    if (metro_lines) {
      if (metro_lines?.some((item) => item?.id === value?.id)) {
        const newData = metro_lines?.filter((item) => item?.id !== value?.id)
        dispatch(setMetroLines(newData))
      } else {
        dispatch(setMetroLines([...metro_lines, value]))
      }
    } else {
      dispatch(setMetroLines([value]))
    }
  }
  const handleSetNearestPlaces = (value: FilterNearestPlasesType) => {
    if (nearest_places) {
      if (nearest_places?.some((item) => item?.id === value?.id)) {
        const newData = nearest_places?.filter((item) => item?.id !== value?.id)
        dispatch(setNearestPlaces(newData))
      } else {
        dispatch(setNearestPlaces([...nearest_places, value]))
      }
    } else {
      dispatch(setNearestPlaces([value]))
    }
  }

  return (
    <>
      <Spin spinning={isLoading}>
        <div
          className="flex flex-col gap-4 mobileTopFilter"
          style={{ height: "calc(100vh - 240px)", overflow: "auto" }}
        >
          {!isEmptyArr(data?.complexes) && (
            <div className="flex flex-col gap-3 bg-white p-3 pb-4 rounded-xl">
              {data?.complexes?.map((item) => (
                <div
                  key={item?.id}
                  className="flex items-center justify-between pb-3 last:pb-0 border-b border-solid border-[#EAECF0] last:border-b-0 cursor-pointer"
                  onClick={() => handleSetComplexes(item)}
                >
                  <div className="flex items-center gap-4">
                    <Complex1Icon />
                    <div className="flex flex-col">
                      <span className="text-[#344054] font-medium">
                        {item?.name}
                      </span>
                      <span className="text-[#667085] text-xs">
                        {`${
                          item?.district?.name[
                            locale as keyof typeof item.district.name
                          ]
                        }, ${
                          item?.district?.city?.name[
                            locale as keyof typeof item.district.city.name
                          ]
                        }`}
                      </span>
                    </div>
                  </div>
                  {complexes?.some((elem) => elem?.id === item?.id) && (
                    <RegCheckIcon />
                  )}
                </div>
              ))}
            </div>
          )}
          {!isEmptyArr(data?.metro_lines) && (
            <div className="flex flex-col gap-3 bg-white p-3 pb-4 rounded-xl">
              {data?.metro_lines?.map((item) => (
                <div
                  key={item?.id}
                  className="flex items-center justify-between pb-3 last:pb-0 border-b border-solid border-[#EAECF0] last:border-b-0 cursor-pointer"
                  onClick={() => handleSetMetroLines(item)}
                >
                  <div className="flex items-center gap-4">
                    <LocomotiveIcon fill={item?.color} />
                    <span className="text-[#344054] font-medium">
                      {item?.name[locale as keyof typeof item.name]}
                    </span>
                  </div>
                  {metro_lines?.some((elem) => elem?.id === item?.id) && (
                    <RegCheckIcon />
                  )}
                </div>
              ))}
            </div>
          )}
          {!isEmptyArr(data?.nearest_places) && (
            <div className="flex flex-col gap-3 bg-white p-3 pb-4 rounded-xl">
              {data?.nearest_places?.map((item) => (
                <div
                  key={item?.id}
                  className="flex items-center justify-between pb-3 last:pb-0 border-b border-solid border-[#EAECF0] last:border-b-0 cursor-pointer"
                  onClick={() => handleSetNearestPlaces(item)}
                >
                  <div className="flex items-center gap-4">
                    <LocomotiveIcon fill={item?.metro_line?.color} />
                    <div className="flex flex-col">
                      <span className="text-[#344054] font-medium">
                        {item?.name[locale as keyof typeof item.name]}
                      </span>
                      <span className="text-[#667085] text-xs">
                        {
                          item?.metro_line?.name[
                            locale as keyof typeof item.metro_line.name
                          ]
                        }
                      </span>
                    </div>
                  </div>
                  {nearest_places?.some((elem) => elem?.id === item?.id) && (
                    <RegCheckIcon />
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </Spin>
      <div
        className="fixed h-[90px] bottom-0 w-full rounded-t-2xl bg-white right-0 flex items-center justify-center p-4 pb-7"
        style={{ boxShadow: " 0px -4px 9.8px 0px #0000001A" }}
      >
        <Button
          type="primary"
          className="w-full h-[46px] rounded-xl"
          onClick={() =>
            dispatch(setOpenRegionSelect({ type: undefined, value: false }))
          }
        >
          {layoutLang[String(locale)]["Saqlash"]}
        </Button>
      </div>
    </>
  )
}

export default MetroSearch
