import React, { useEffect } from "react"
import styles from "./filterDesktop.module.scss"
import { Button, Input } from "antd"
import FirstFilterIcon from "../../assets/icons/FirstFilterIcon"
import SearchIcon from "../../assets/icons/SearchIcon"
import FilterDesktopModal from "./filter-desktop-modal/FilterDesktopModal"
import FilterRoomModal from "./filter-room-modal/FilterRoomModal"
import FilterPriceModal from "./filter-price-modal/FilterPriceModal"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks"
import { useRouter } from "next/router"
import { LOCATION } from "../../utils/constants/queryParamsKeys"
import { useTranslations } from "next-intl"
import { favouritesReducerActions } from "../../store/reducers/favouritesReducer"
import qs from "query-string"
import { clearObj } from "../../utils/helpers/clearObj"

const FilterDesktop = () => {
  const dispatch = useAppDispatch()
  const t = useTranslations("body.Top")
  const { push, query: getQuery, pathname } = useRouter()
  const { filter } = useAppSelector((state) => state.favouritesReducer)
  const { setChangeComplexName, setOpenMapDrawer, setStudio } =
    favouritesReducerActions
  const location = String(getQuery?.location).split("&")[0]
  const viewType = pathname.includes("karta")

  const query = {
    ...(clearObj(qs.parse(getQuery?.location as any)) as any),
    location,
  }

  useEffect(() => {
    if (getQuery?.complex_name) {
      dispatch(setChangeComplexName(String(getQuery?.complex_name)))
    }
    if (getQuery?.rooms_count) {
      if (Array.isArray(getQuery?.rooms_count)) {
        dispatch(setStudio(getQuery?.rooms_count))
      } else {
        dispatch(setStudio([getQuery?.rooms_count]))
      }
    }
  }, [getQuery])

  const onFinish = () => {
    const newValues = {
      ...filter,
      ...getQuery,
      min_total_price: filter?.min_total_price,
      max_total_price: filter?.max_total_price,
      min_prepayment: filter?.min_prepayment,
      max_prepayment: filter?.max_prepayment,
      price_min_per_area: filter?.price_min_per_area,
      price_max_per_area: filter?.price_max_per_area,
      complex_name: filter?.complex_name,
      rooms_count: filter?.rooms_count,
    }

    let key: keyof typeof newValues
    const updatedFields = {
      ...newValues,
    }

    for (key in updatedFields) {
      !updatedFields[key] && delete updatedFields[key]
      updatedFields[key] === "undefined" && delete updatedFields[key]
    }

    const getNewQuery = viewType
      ? updatedFields
      : {
          ...updatedFields,
          [LOCATION]: query[LOCATION]?.toString()?.split("&")[0],
        }

    push({
      query: getNewQuery,
    })
  }

  const filterLength = Object.keys(query)?.includes("location")
    ? Object.keys(getQuery)?.length - 1
    : Object.keys(getQuery)?.length

  return (
    <div
      className={styles.container}
      onClick={() => {
        dispatch(setOpenMapDrawer({ id: null, open: false }))
      }}
    >
      <div className={`wrapper ${styles.body}`}>
        <FilterRoomModal />
        <FilterPriceModal />
        <Input
          size="large"
          prefix={<SearchIcon size="16" color="#A3A6B7" />}
          placeholder={t("Жилой комплекс")}
          className="customInput"
          value={filter?.complex_name ? String(filter?.complex_name) : ""}
          onChange={(e) => dispatch(setChangeComplexName(e.target.value))}
          onPressEnter={onFinish}
        />
        <FilterDesktopModal>
          <div
            className={filterLength > 0 ? styles.activeFilter : styles.filter}
          >
            <FirstFilterIcon size="20" color="#464255" />
            <span>{t("Фильтр")}</span>
          </div>
        </FilterDesktopModal>
        <Button
          size="large"
          onClick={onFinish}
          className="!bg-[#0DC683] !text-white !border-[#0DC683]"
        >
          <SearchIcon size="20" color="#ffffff" />
          <span>{t("Найти")}</span>
        </Button>
      </div>
    </div>
  )
}

export default FilterDesktop
