import { useMutation, useQueryClient } from "@tanstack/react-query"
import $api from "../../../services/RequestService"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { ErrorRes, SuccessRes } from "../../../utils/models/responseType"
import { endpoints } from "../utils/constants/endpoints"
import { queryKeys } from "pages/main-layout/utils/constants/queryKeys"

// update favourites
export function useUpdateFavourites() {
  return useMutation<SuccessRes, ErrorRes, { id: number; key: string }>(
    async (req) => {
      const res = await $api.post(endpoints.FAVOURITE, {
        [req.key]: req.id,
      })
      return res.data
    },
    {
      onError: errorHandler,
    }
  )
}

export function useDeleteFavorites() {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, { id: number; key: string }>(
    async (req) => {
      const res = await $api.delete(endpoints.FAVOURITE_DELETE, {
        data: {
          [req.key]: req.id,
        },
      })
      return res.data
    },

    {
      onSuccess: () => {
        qc.invalidateQueries({ queryKey: [queryKeys.FAVOURITES] })
      },
      onError: errorHandler,
    }
  )
}
