import dayjs from "dayjs"
import queryString, { stringify } from "query-string"
import { ParsedUrlQuery } from "querystring"
import $api from "../../../services/RequestService"
import { dayjsFormats } from "../../../utils/constants/dayjsFormats"
import { SIZE, YEAR } from "../../../utils/constants/queryParamsKeys"
import { isEmptyObject } from "../../../utils/helpers/isEmptyObject"
import { endpoints } from "../utils/constants/apis"
import { EXCLUDE_URL } from "../utils/constants/excludeUrl"

// complexes
export const fetchComplexes = (query?: ParsedUrlQuery, complex_id?: number) => {
  let url = `/complex/view`
  let year

  const queryCopy: ParsedUrlQuery = {
    page: "1",
    [Array.isArray(query?.metro_line_ids)
      ? "metro_line_ids"
      : "metro_line_ids[]"]: query?.metro_line_ids,
    [Array.isArray(query?.nearest_place_ids)
      ? "nearest_place_ids"
      : "nearest_place_ids[]"]: query?.nearest_place_ids,
    [Array.isArray(query?.complex_ids) ? "complex_ids" : "complex_ids[]"]:
      query?.complex_ids,
    ...query,
  }

  if (complex_id) {
    url += `?${stringify({
      [`complex_ids[]`]: complex_id,
    })}`

    url = queryString.exclude(url, [EXCLUDE_URL.location])
  } else if (!isEmptyObject(queryCopy)) {
    if (queryCopy[YEAR] && Array.isArray(queryCopy[YEAR])) {
      year = dayjs
        .max([...(queryCopy[YEAR] as string[]).map((item) => dayjs(item))])
        ?.format(dayjsFormats.DEFAULT_DATE)
    }

    url += `?${stringify(
      {
        ...queryCopy,
        [SIZE]: 14,
        [YEAR]: year,
      },
      { arrayFormat: "bracket" }
    )}`

    url = queryString.exclude(url, [EXCLUDE_URL.location])
  }

  // query

  return {
    url,
    fetch: async () => {
      const res = await $api.get(url)
      return res.data.data
    },
  }
}

// classes
export const fetchClasses = async () => {
  const res = await $api.get(endpoints.CLASSES_SELECT)
  return res.data.data
}
