import React, { useEffect } from "react"
import { Dropdown } from "antd"

import styles from "./filterRoomModal.module.scss"
import ArrowIcon from "../../../assets/icons/ArrowIcon"
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks"
import { favouritesReducerActions } from "../../../store/reducers/favouritesReducer"
import { useRouter } from "next/router"
import { useTranslations } from "next-intl"

const roomCount = [
  {
    key: "1",
    value: "1",
  },
  {
    key: "2",
    value: "2",
  },
  {
    key: "3",
    value: "3",
  },
  {
    key: "4",
    value: "4+",
  },
]

const FilterRoomModal = () => {
  const t = useTranslations("common.FilterMobile")
  const dispatch = useAppDispatch()
  const { setStudio } = favouritesReducerActions
  const { filter } = useAppSelector((state) => state.favouritesReducer)
  const { query } = useRouter()

  const finalData = filter?.rooms_count

  useEffect(() => {
    if (finalData) {
      if (Array.isArray(finalData)) {
        dispatch(setStudio(finalData?.map((item) => String(item))))
      } else {
        dispatch(setStudio([String(finalData)]))
      }
    }
  }, [query])

  const handleCheckRoom = (value: string) => {
    if (finalData?.includes(String(value)) && Array.isArray(finalData)) {
      const newValue = finalData?.filter((item) => item !== String(value))
      dispatch(setStudio(newValue))
    } else {
      value &&
        dispatch(
          setStudio([finalData, String(value)]?.flat(Infinity) as string[])
        )
    }
  }

  return (
    <>
      <Dropdown
        overlay={
          <div className={styles.container}>
            <div
              className={styles.container_top}
              onClick={() => handleCheckRoom("0")}
              style={{
                color: finalData?.includes("0") ? "#1B815F" : "",
                backgroundColor: finalData?.includes("0") ? "#DAF3EB" : "",
              }}
            >
              <span>{t("Студия")}</span>
            </div>
            <div className={styles.container_bottom}>
              {roomCount?.map((item) => (
                <div
                  key={item?.key}
                  onClick={() => {
                    handleCheckRoom(item?.key)
                  }}
                  style={{
                    color: finalData?.includes(String(item?.key))
                      ? "#1B815F"
                      : "",
                    backgroundColor: finalData?.includes(String(item?.key))
                      ? "#DAF3EB"
                      : "",
                  }}
                >
                  {item?.value}
                </div>
              ))}
            </div>
          </div>
        }
        trigger={["click", "hover"]}
        mouseEnterDelay={1000}
        mouseLeaveDelay={0.3}
      >
        <div className={styles.dropDownBtn}>
          <span className="placeholder">
            {Array.isArray(finalData) && finalData?.length > 0
              ? finalData
                ?.map((item) => {
                  return item === "0" ? `${t("Студия")}` : `${item}`
                })
                ?.join()
              : t("Количество комнат")}
          </span>
          <ArrowIcon />
        </div>
      </Dropdown>
    </>
  )
}

export default FilterRoomModal
