import { useMutation } from "@tanstack/react-query"
import { isBrowser } from "utils/helpers/isBrowser"
import $api from "./RequestService"

export function useCheckHasToken() {
  return useMutation(async () => {
    const res = await $api.post(`/anonymous-user/create`, {
      anonymous_user_id: isBrowser()
        ? localStorage.getItem("anonymous_user_id")
        : "",
    })

    return res.data
  })
}
