import React from 'react';

const FifthSmileIcon: React.FC = () => {
 return (
   <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.995 2.00016C28.1977 1.95991 36.0263 9.42773 35.9999 19.0589C35.9742 28.4536 28.3803 36.0446 18.935 36.0119C9.41912 35.9798 1.99071 28.251 1.98843 19.053C1.98593 9.59941 9.56145 2.00016 18.995 2.00016Z"
      fill="#FECA00" />
    <path
      d="M17.4834 17.5865C17.4845 18.252 17.2996 18.8717 17.0266 19.4717C16.414 20.8198 15.5621 22.0066 14.5821 23.108C13.79 23.9984 12.9258 24.814 12.016 25.5814C11.8905 25.6871 11.8075 25.6948 11.6702 25.6093C10.2635 24.7304 8.93842 23.742 7.75246 22.5811C6.8939 21.7415 6.12337 20.8207 5.55932 19.751C4.48658 17.7168 5.32721 15.1658 7.41983 14.3661C8.52185 13.9452 9.52539 14.1846 10.4556 14.8481C10.6305 14.9725 10.793 15.1134 10.941 15.2688C11.0787 15.414 11.1486 15.4478 11.2892 15.2529C11.6938 14.6922 12.2104 14.2504 12.8609 13.9979C14.0181 13.5488 15.0845 13.7816 16.0347 14.5223C16.9917 15.267 17.4877 16.3748 17.4834 17.5865Z"
      fill="#E84047" />
    <path
      d="M32.9002 17.849C32.9293 18.734 32.64 19.4978 32.1867 20.2169C31.3964 21.4704 30.3861 22.5255 29.2679 23.4845C28.3568 24.2655 27.3752 24.9508 26.3658 25.5975C26.2419 25.6769 26.1475 25.7196 26.0134 25.6041C24.4079 24.2222 22.9146 22.7382 21.7772 20.9339C21.2902 20.1624 20.8521 19.361 20.6343 18.4635C20.1399 16.4263 21.3474 14.3591 23.3139 13.8707C24.3267 13.6193 25.2241 13.8739 26.0207 14.519C26.2984 14.7439 26.5548 14.9969 26.7576 15.2949C26.8407 15.4167 26.8815 15.4392 27.0056 15.3176C27.6609 14.6761 28.4171 14.1955 29.3585 14.1696C30.716 14.1322 31.7147 14.7834 32.4027 15.9239C32.7561 16.5019 32.9293 17.1721 32.9002 17.849Z"
      fill="#E84047" />
    <path
      d="M18.9961 29.0861C17.1107 29.0814 15.4439 27.6674 15.0684 25.7527C14.9777 25.2945 14.9357 24.8341 15.0003 24.3671C15.0176 24.2398 15.0305 24.1756 15.1912 24.2196C16.9795 24.7102 18.8001 24.7976 20.6379 24.6253C21.3674 24.5575 22.0895 24.4257 22.7959 24.2314C22.9048 24.2014 22.9973 24.1529 23.0175 24.3353C23.2335 26.2839 22.1383 28.2894 20.1328 28.9191C19.7652 29.0349 19.3815 29.0913 18.9961 29.0861Z"
      fill="#171615" />
   </svg>
 );
}

export default FifthSmileIcon;